import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import '../styles/pages/Issues.css'

const Issues = ({
  data: {
    allPrismicIssue: { edges: issues },
  },
}) => {
  return (
  <Layout>
    <SEO
      title="CONTANGO - ISSUES"
      keywords={['Contango Journal', 'Political Theory']}
    />
    <div className="issues">
      <h1>Issues</h1>
      <div className="issuesContainer">
        {issues.map(({ node: issue }) => (
          <Link to={issue.uid} className="issueIcon" key={issue.id}>
            <div className="issueCover">
              <h1>{issue.data.issue_title.text}</h1>
            </div>
            <img
              src={issue.data.issue_cover_image.url}
              alt={issue.data.issue_title.text}
            />
          </Link>
        ))}
      </div>
    </div>
  </Layout>
)}

export default Issues

export const pageQuery = graphql`
  query IssuesQuery {
    allPrismicIssue(
      sort: { order: DESC, fields: [data___issue_publication_date] }
    ) {
      edges {
        node {
          id
          uid
          data {
            issue_title {
              text
              html
            }
            issue_cover_image {
              alt
              copyright
              url
            }
            issue_description {
              text
            }
            issue_publication_date
          }
        }
      }
    }
  }
`
